// #region Imports
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import 'ngx-toastr/toastr';
// #endregion

// #region Base Styles
.btn {
  @apply pt-2 text-cyan-500 px-2 text-sm hover:text-cyan-700;
}

.btn-warning {
  @apply pt-2 text-orange-400
}


.unselectable {
  user-select: none;
  /* Standard syntax */
  -webkit-user-select: none;
  /* For Safari */
  -moz-user-select: none;
  /* For Firefox */
  -ms-user-select: none;
  /* For Internet Explorer/Edge */
}

#content {
  margin-left: 0rem !important;
}

// #endregion

// #region Remove default browser Styles 

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* Chrome, Safari, Edge, Opera */
  -webkit-appearance: none;
  margin: 0;
}

@layer utilities {
  @variants reponsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

input[type=number] {
  /* Firefox */
  -moz-appearance: textfield;
}

.gridContainer {

  height: calc(100vh - 120px) !important;

  kendo-grid {
    max-height: 100% !important;
  }

  .edit-buttons .k-button-solid-base:focus,
  .edit-buttons .k-button-solid-base.k-focus {
    box-shadow: none;
  }
}

kendo-dropdownlist {
  width: 160px;
  height: 40px;
  background-color: #1c4d7a;
  border: none;
  padding: 4px 6px 4px 8px;
}

kendo-dropdownlist:hover {
  background-color: #446d91;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.3);
}

kendo-dropdownlist:hover .value {
  color: #fff;
}

kendo-dropdownlist:hover .title {
  color: #fff;
}

kendo-dropdownlist[aria-expanded="true"] {
  background-color: #ffffff;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.3);
}

kendo-dropdownlist[aria-expanded="true"] .value {
  color: #154876;
}

kendo-dropdownlist[aria-expanded="true"] .title {
  color: #94a3b8;
}


kendo-label>.k-label {
  color: #b3b8c2 !important;
  opacity: 1 !important;
}

kendo-grid-toolbar {
  display: flex;
}

.ngx-toastr-rtl {
  text-align: right;
}

.btn:disabled {
  color:#6c6c84 !important;
  background-color: #dddddd !important;
}
// #endregion